import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AnagraficaFormComponent } from './anagrafica-form/anagrafica-form.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnagraficaSchedaComponent } from './anagrafica-scheda/anagrafica-scheda.component';


@NgModule({
  declarations: [
    AnagraficaFormComponent,
    AnagraficaSchedaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    NgbNavModule,
  ],
  exports: [
    AnagraficaFormComponent
  ]
})
export class AnagraficaModule { }
