import { Routes } from '@angular/router';
import { Page404Component } from 'src/app/components/page404/page404.component';
import { AnagraficaSchedaComponent } from 'src/app/pages/anagrafica/anagrafica-scheda/anagrafica-scheda.component';
import { CorsiElencoComponent } from 'src/app/pages/corsi/corsi-elenco/corsi-elenco.component';
import { DomandeInvioComponent } from 'src/app/pages/corsi/domande-invio/domande-invio.component';
import { DomandeSchedaComponent } from 'src/app/pages/corsi/domande-scheda/domande-scheda.component';
import { DomandeStatoComponent } from 'src/app/pages/corsi/domande-stato/domande-stato.component';
import { HomepageComponent } from 'src/app/pages/homepage/homepage.component';


export const MainLayoutRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: '404',
    component: Page404Component
  },
  {
    path: 'anagrafica',
    component: AnagraficaSchedaComponent
  },
  {
    path: 'corsi/dettaglio/:idAmmissione/:id',
    component: DomandeStatoComponent
  },
  {
    path: 'corsi/invio/:id',
    component: DomandeInvioComponent
  },
  {
    path: 'corsi/dettaglio/:id',
    component: DomandeStatoComponent
  },
  {
    path: 'corsi/scheda/:id',
    component: DomandeSchedaComponent
  },
  {
    path: 'corsi',
    component: CorsiElencoComponent
  },
];

