import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Domanda } from '../domanda';
import { DomandeService } from '../domande.service';
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-domande-invio',
  templateUrl: './domande-invio.component.html',
  styleUrls: ['./domande-invio.component.scss']
})
export class DomandeInvioComponent implements OnInit {
  titoloScheda: string = "Caricamento...";
  linkBase: string;
  ready: boolean = false;
  tipologia: string;
  id: number;
  myItem: Domanda;
  arrayCampi: any[any];
  myForm: FormGroup;
  loading = false;
  formData: FormData;
  documentiDomanda: any;

  constructor(
    private componentsService: ComponentsService,
    private formComponentsService: FormInputService,
    private route: ActivatedRoute,
    private service: DomandeService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
  ) {
    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'idPadre' : {'id' : 'idPadre', 'defValue' : 0, 'type': 'NUM'},
      'tabellaPadre' : {'id' : 'tabellaPadre', 'defValue' : 0, 'type': 'TXT'},
      'domanda' : {'id' : 'domanda', 'nome': 'Domanda di ammissione', 'estensioniAmmesse': ['pdf'], 'required': true, 'type': 'FIL'},
    }

    this.formData = new FormData();

  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        if (params.idAmmissione != undefined) { // domanda di iscrizione
        }
        else {
          this.tipologia = "ammissione";
          this.id = params.id;
          this.linkBase = "/corsi/dettaglio/"+this.id;
        }

        forkJoin([
          this.service.getStato(this.tipologia, this.id),
          this.service.getDocumentiDomanda(this.tipologia, this.id)
        ])
        .subscribe(
          ([myItem,
            documentiDomanda
          ]) => {
            if (!myItem.richiestaModificabile) {
              this.notificationsService.show('error', '', 'Azione non permessa');
              this.onBack();
            }
            this.myItem = myItem;
            this.documentiDomanda = documentiDomanda;

            if (Object.keys(this.myItem.arrayDocumenti).length > 0) {
              for (let key in this.myItem.arrayDocumenti) {
                this.arrayCampi[key] = {'id' : key, 'nome': this.myItem.arrayDocumenti[key].nome, 'estensioniAmmesse': ['pdf'], 'type': 'FIL', 'required': this.myItem.arrayDocumenti[key].obbl}
              }
            }
            this.titoloScheda = "Invio domanda di <b>"+this.tipologia+"</b> al Corso di " + this.myItem.scuola + " - " + this.myItem.tipologia;
            this.myForm = this.formComponentsService.createForm(this.arrayCampi);
            this.myForm.patchValue(this.documentiDomanda);
            this.myForm.patchValue({'id' : this.documentiDomanda.id});
            this.myForm.patchValue({'idPadre' : this.id});
            this.myForm.patchValue({'tabellaPadre' : this.tipologia});
            console.log(this.documentiDomanda);
            console.log(this.myForm);

            this.formComponentsService.activateLoader(false);
            this.ready = true;
        },
        (error) => {
          this.formComponentsService.activateLoader(true);
          this.mainEngineService.errorHandler(error);
        });
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onAddFile(event: any) {
    if (event.name != undefined) {
      this.formData.append(event.id + '_file', event);
    }
    else {
      this.formData.delete(event.id + '_file');
    }
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return false;
    }

    Swal.fire({
      title: 'Confermi di inviare la domanda alla segreteria?',
      text: 'Eventuali ulteriori modifiche potranno essere effettuate solamente contattando la segreteria',
      showCancelButton: true,
      confirmButtonText: "Conferma",
      cancelButtonText: 'Annulla',
      icon: 'warning'
    }).then((result) => {
      if (result.value) {
        this.inviaIscrizione();
      }
    });
  }


  inviaIscrizione() {
    this.loading = true;

    const obj = this.myForm.getRawValue();
    for (const k in obj) {
      this.formData.append(k, obj[k]);
    }

    this.service.sendDocumentiDomanda(this.formData)
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.onBack();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onBack(this.linkBase);
  }

}
