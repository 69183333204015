import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericItem } from 'src/app/components/class/generic-item';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Domanda } from './domanda';
import { ImpostazioniDomanda } from './impostazioni-domanda';

@Injectable({
  providedIn: 'root'
})
export class DomandeService {
  apiAmmissioniUrl: string;
  apiIscrizioniUrl: string;
  apiDomandaUrl: string;
  linkBase: string = "domande";

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiAmmissioniUrl = this.mainEngineService.baseUrl + 'ammissioni/';
    this.apiIscrizioniUrl = this.mainEngineService.baseUrl + 'iscrizioni/';
    this.apiDomandaUrl = this.mainEngineService.baseUrl + 'domande/';
  }
/*
  getElenco(): Observable<Domanda[]> {
    return this.http.get<Domanda[]>(this.apiUrl)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }

  */

  getImpostazioniDomanda(): Observable<ImpostazioniDomanda> {
    return this.http.get<ImpostazioniDomanda>(this.apiDomandaUrl+"getImpostazioniDomanda")
  }

  getElencoAmmissioni(): Observable<Domanda[]> {
    return this.http.get<Domanda[]>(this.apiAmmissioniUrl)
  }

  getTipologieRichiesta(tipologia, id: number = 0): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.apiDomandaUrl+"getTipologieRichiesta/?tipologia="+tipologia+"&id="+id)
  }

  getDatiDomanda(idTipologia): Observable<any[any]> {
    return this.http.get<any[any]>(this.apiDomandaUrl+"getDatiDomanda/?idTipologia="+idTipologia)
  }

  sendAmmissione(formValue1: any[], formValue2: any[], formValue3: any[], formValue3Tabella: any[], formValue4: any[]) {
    return this.http.post<any>(this.apiAmmissioniUrl+"/?tipologia=domanda" , {formValue1, formValue2, formValue3, formValue3Tabella, formValue4});
  }

  getStato(tipologia: string, id: number): Observable<Domanda> {
    let url = (tipologia == "ammissione") ? this.apiAmmissioniUrl : this.apiIscrizioniUrl;

    return this.http.get<Domanda>(url+"?tipologia=stato&id=" + id);
  }

  getDomanda(tipologia: string, id: number): Observable<Domanda> {
    let url = (tipologia == "ammissione") ? this.apiAmmissioniUrl : this.apiIscrizioniUrl;

    return this.http.get<Domanda>(url+"?tipologia=domanda&id=" + id);
  }

  sendDocumentiDomanda(formData: FormData) {
    return this.http.post<any>(this.apiAmmissioniUrl+"/?tipologia=documenti" , formData);
  }

  getDocumentiDomanda(tipologia: string, id: number): Observable<any> {
    let url = (tipologia == "ammissione") ? this.apiAmmissioniUrl : this.apiIscrizioniUrl;

    return this.http.get<any>(url+"?tipologia=documentiDomanda&id=" + id);
  }

}
