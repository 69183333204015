import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Anagrafica } from './anagrafica';

@Injectable({
  providedIn: 'root'
})
export class AnagraficaService {
  apiCommonOpUrl: string;
  apiUrl: string;
  linkBase: string = "anagrafica";

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.apiCommonOpUrl = this.mainEngineService.baseUrl + 'customOperations/';
  }

  getTestoPrivacy() {
    return this.http.get<string>(this.apiCommonOpUrl + 'getTestoPrivacy')
  }

  sendItem(formValue: any[]) {
    return this.http.post<any>(this.apiUrl , formValue);
  }

  getItem(): Observable<Anagrafica> {
    return this.http.get<Anagrafica>(this.apiUrl)
  }

}
