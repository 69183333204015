import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { FormInputService } from '../../scheda/formInput/form-input.service';
import { ReservedAccessService } from '../reserved-access.service';
import { ParametriControlloRegistrazione } from './parametri-controllo-registrazione';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  parametriControlloRegistrazione: ParametriControlloRegistrazione;
  loading: boolean = false;
  arrayCampiStep1: any[any];
  myFormStep1: FormGroup;
  arrayPulsanti: any[any];
  cf: string = '';
  natoIl: string = '';
  alertCfExists: boolean = false;
  step: number = 1;

  constructor(
    private service: ReservedAccessService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService
  ) {
    this.arrayCampiStep1 = {
      'cf' : {'id' : 'cf', 'required': true, 'nome' : 'Codice fiscale', 'type': 'CF'},
    };
    this.arrayPulsanti = {
      'prosegui' : {'type' : 'forward', 'testo' : 'Prosegui'},
      'indietro' : {'type' : 'back', 'testo' : 'Torna alla pagina di login'},
    };

  }

  ngOnInit(): void {
    this.service.setParentLayout$("large");
    this.loading = true;
    this.formComponentsService.activateLoader(true);

    this.myFormStep1 = this.formComponentsService.createForm(this.arrayCampiStep1);

    this.service.getParametriControlloRegistrazione()
    .subscribe(data => {
      this.parametriControlloRegistrazione = data;
      this.loading = false;
      this.formComponentsService.activateLoader(false);
      })
  }


  verificaCf() {
    this.alertCfExists = false;
    this.cf = this.myFormStep1.controls['cf'].value;
    if (!this.myFormStep1.valid) {
      this.formComponentsService.validateAllFormFields(this.myFormStep1);
      return false;
    }

    this.loading = true;

    this.service.verificaCf(this.myFormStep1.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        if (response.exists) {
          this.alertCfExists = true;
        }
        else {
          this.natoIl = response.natoIl;
          this.goToStep(2);
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );

  }


  goToStep(step) {
    this.step = step;
  }


  onSubmitDone() {
    this.goToStep(3);

  }
}
