
import { Routes } from '@angular/router';
import { LoginComponent } from 'src/app/components/reservedAccess/login/login.component';
import { RecoverComponent } from 'src/app/components/reservedAccess/recover/recover.component';
import { RegisterComponent } from 'src/app/components/reservedAccess/register/register.component';

export const LoginLayoutRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'recover',
    component: RecoverComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'confermaPassword/:userId/:token',
    component: LoginComponent
  },
  {
    path: 'confermaEmail/:userId/:token',
    component: LoginComponent
  }
];
