import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Domanda } from '../domanda';
import { DomandeService } from '../domande.service';

@Component({
  selector: 'app-domande-stato',
  templateUrl: './domande-stato.component.html',
  styleUrls: ['./domande-stato.component.scss']
})
export class DomandeStatoComponent implements OnInit {
  myItem: Domanda;
  titoloScheda: string = "Caricamento...";
  linkBase: string;
  ready: boolean = false;
  statoRichiesta: string;
  arrayPulsanti: any[any];
  tipologia: string;
  id: number;

  constructor(
    private formComponentsService: FormInputService,
    private service: DomandeService,
    private mainEngineService: MainEngineService,
    private componentsService: ComponentsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
  ) {
    this.arrayPulsanti = {
      'modifica' : {'type' : 'edit', 'btnSize': 'lg'},
      'pdf': {'type' : 'custom', 'testo' : 'Crea Pdf', 'colorClass' : 'info', 'icon': 'mdi mdi-file-pdf', 'actionTooltip' : 'Scarica la domanda in formato PDF', 'btnSize': 'lg', 'ML': true},
      'inviaDomanda': {'type' : 'custom', 'testo' : 'Invia la domanda', 'colorClass' : 'success', 'icon': 'mdi mdi-email-send', 'actionTooltip' : 'Carica gli allegati ed invia la domanda alla segreteria', 'btnSize': 'lg', 'ML': true},
    };
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);
    this.linkBase = "/corsi";

    this.route.params.subscribe(
      (params) => {
        if (params.idAmmissione != undefined) { // domanda di iscrizione
        }
        else {
          this.tipologia = "ammissione";
          this.id = params.id;
        }

        this.service.getStato(this.tipologia, this.id)
        .subscribe(data => {
          this.myItem = data;
          this.titoloScheda = "Stato domanda di <b>"+this.tipologia+"</b> al Corso di " + this.myItem.scuola + " - " + this.myItem.tipologia;
          this.statoRichiesta = "Stato richiesta: <span style='color: " + this.myItem.stato_colore + "'>" + this.myItem.stato + "</span> - <span style='color: " + this.myItem.proxStep_colore + "'>" + this.myItem.proxStep + "</span>";

          this.ready = true;
          this.formComponentsService.activateLoader(false);
        },
        (error) => {
          this.formComponentsService.activateLoader(true);
          this.mainEngineService.errorHandler(error);
        });

        this.formComponentsService.activateLoader(false);

      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  alertNonModificabile() {
    this.notificationsService.show('warning', '', 'La domanda è stata inviata ed è in attesa di valutazione. Per effettuare correzioni, contattare la segreteria');
  }


}
