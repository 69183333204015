export const ELEMENTI_DICHIARAZIONE = new Array();

ELEMENTI_DICHIARAZIONE[1] = {'id' : 'dich_1', 'nome': 'è consapevole che l\'iscrizione ai corsi AFAM è compatibile con l\'iscrizione ad altri corsi di livello universitario, fino ad un massimo di 90 crediti annui (D.M. 28 settembre 2011)', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[2] = {'id' : 'dich_2', 'nome': 'si vuole avvalere della contemporanea iscrizione e di essere iscritto presso', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_2_campo1', 'nome': 'Università degli Studi di', 'required': false},
    {'id' : 'dich_2_campo2', 'nome': 'facoltà di', 'required': false},
    {'id' : 'dich_2_campo3', 'nome': 'per n. crediti', 'required': false}
  ]
};
ELEMENTI_DICHIARAZIONE[3] = {'id' : 'dich_3', 'nome': 'ha conseguito il', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_3_campo1', 'nome': 'Diploma di Laurea', 'required': false, 'items': {'T' : 'Triennale', 'B' : 'Biennale'}},
    {'id' : 'dich_3_campo2', 'nome': 'in', 'required': false},
    {'id' : 'dich_3_campo3', 'nome': 'presso l\'Università di', 'required': false},
    {'id' : 'dich_3_campo4', 'nome': 'nell\'A.A.', 'required': false}
  ]
};
ELEMENTI_DICHIARAZIONE[4] = {'id' : 'dich_4', 'nome': 'ha conseguito il Diploma di Vecchio Ordinamento', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_4_campo1', 'nome': 'in', 'required': false},
    {'id' : 'dich_4_campo2', 'nome': 'presso il Conservatorio di', 'required': false},
    {'id' : 'dich_4_campo3', 'nome': 'nell\'A.A.', 'required': false}
  ]
};
ELEMENTI_DICHIARAZIONE[6] = {'id' : 'dich_6', 'nome': 'è consapevole che l\'iscrizione presso una Istituzione AFAM non è compatibile con l\'iscrizione presso un\'altra Istituzione AFAM', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[7] = {'id' : 'dich_7', 'nome': 'è in possesso del diploma di Scuola Media Superiore', 'type': 'BOL', 'fullW': true, 'required': false};
ELEMENTI_DICHIARAZIONE[8] = {'id' : 'dich_8', 'nome': 'è a conoscenza che le iscrizioni dovranno pervenire entro il <b>[dataTermine]</b> e che [modalitaInvioDomanda]', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[9] = {'id' : 'dich_9', 'nome': 'è iscritto presso la', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_9_campo1', 'nome': 'Scuola', 'required': false, 'items': {"e" : "elementare", "m" : "media", "s" : "superiore", "a" : "altro"}},
    {'id' : 'dich_9_campo2', 'nome': 'di', 'required': false},
    {'id' : 'dich_9_campo3', 'nome': 'classe', 'required': false},
  ]
};
ELEMENTI_DICHIARAZIONE[10] = {'id' : 'dich_10', 'nome': 'ha conseguito il Diploma di Maturità', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_10_campo1', 'nome': 'presso', 'required': false},
    {'id' : 'dich_10_campo2', 'nome': 'di', 'required': false},
    {'id' : 'dich_10_campo3', 'nome': 'nell\'A.S.', 'required': false},
    {'id' : 'dich_10_campo4', 'nome': 'votazione', 'required': false},
  ]
};
ELEMENTI_DICHIARAZIONE[11] = {'id' : 'dich_11', 'nome': 'è iscritto, nel corrente A.A., al', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_11_campo1', 'nome': 'Corso di Laurea', 'required': false, 'items': {'T' : 'Triennale', 'B' : 'Biennale'}},
    {'id' : 'dich_11_campo2', 'nome': 'in', 'required': false},
    {'id' : 'dich_11_campo3', 'nome': 'presso l\'Università di', 'required': false},
  ]
};
ELEMENTI_DICHIARAZIONE[12] = {'id' : 'dich_12', 'nome': 'è consapevole che l\'iscrizione di un ISSM non è compatibile con l\'iscrizione presso un altro ISSM', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[13] = {'id' : 'dich_13', 'nome': 'è a conoscenza che le domande incomplete o pervenute oltre i termini non saranno accettate', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[14] = {'id' : 'dich_14', 'nome': 'si impegna al pagamento della seconda rata della tassa di frequenza entro e non oltre il <b>[scadenzaRata2]</b> e della terza rata della tassa di frequenza entro e non oltre il <b>[scadenzaRata3]</b> [vedi modalità di pagamento]; la segreteria non invierà alcun avviso di scadenza.', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[15] = {'id' : 'dich_15', 'nome': 'è in possesso del', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_15_campo1', 'nome': 'Titolo di studi musicali', 'required': false},
    {'id' : 'dich_15_campo2', 'nome': 'conseguito presso ISSM di', 'required': false},
  ]
};
ELEMENTI_DICHIARAZIONE[16] = {'id' : 'dich_16', 'nome': 'è iscritto presso la', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_16_campo1', 'nome': 'Scuola Superiore', 'required': false},
    {'id' : 'dich_16_campo2', 'nome': 'di', 'required': false},
    {'id' : 'dich_16_campo3', 'nome': 'classe', 'required': false},
  ]
};
ELEMENTI_DICHIARAZIONE[17] = {'id' : 'dich_17', 'nome': 'ha conseguito il Diploma di Maturità (*)<br><small>(*) Il diploma di Scuola Media Superiore, essendo titolo d\'accesso al Triennio, deve comunque essere conseguito entro il termine del corso di studi per il quale si chiede l\'ammissione, onsapevole che l\'ammissione stessa si intende \'con riserva\' fino al conseguimento dello stesso.</small>', 'type': 'BOL', 'fullW': true, 'required': false,
  'subItems': [
    {'id' : 'dich_17_campo1', 'nome': 'presso', 'required': false},
    {'id' : 'dich_17_campo2', 'nome': 'di', 'required': false},
    {'id' : 'dich_17_campo3', 'nome': 'nell\'A.S.', 'required': false},
    {'id' : 'dich_17_campo4', 'nome': 'votazione', 'required': false},
  ]
};
ELEMENTI_DICHIARAZIONE[18] = {'id' : 'dich_18', 'nome': 'nel caso in cui non possegga il diploma di Scuola Media Superiore, si impegna al conseguimento dello stesso entro il termine del corso di studi', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[19] = {'id' : 'dich_19', 'nome': 'è a conoscenza che le iscrizioni dovranno pervenire:<br>-per coloro che si iscriveranno in prova: <b>entro 5 giorni dall\'esame di ammissione</b>;<br>- per coloro che dovranno sostenere eventuali esami di riparazione o certificazioni di competenza: <b>entro 5 giorni dall\'esame</b>;- per coloro che non devono sostenere alcun esame o certificazione: entro il <b>[dataTermine]</b>', 'type': 'BOL', 'fullW': true, 'altRequired': true};
ELEMENTI_DICHIARAZIONE[20] = {'id' : 'dich_20', 'nome': 'è a conoscenza che [modalitaInvioDomanda]', 'type': 'BOL', 'fullW': true, 'altRequired': true};

ELEMENTI_DICHIARAZIONE[99] = {'id' : 'dich_99', 'nome': 'in assenza di titolo di studio musicale, ha sostenuto i seguenti esami presso gli ISSM (indicare tutti gli esami -compimenti e/o licenze e/o certificazioni e/o idoneità):', 'type': 'BOL', 'fullW': true};
