import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  constructor() { }


  confermaElimina(funcName, params) {
    const that = this;
    Swal.fire({
      icon: 'question',
      title: 'Confermi di voler eliminare questo elemento?',
      input: 'checkbox',
      inputPlaceholder: 'Si, voglio eliminarlo',
      text: "L'elemento eliminato non potrà più essere recuperato",
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      inputValidator: value => !value && "Devi spuntare la casella di conferma per eliminare l'elemento"
    }).then(function(result) {
      if (result.isConfirmed) {
        funcName[`onSuccess`](params);
      }
    })
  }


  confermaGenerico(funcName, params) {
    const that = this;
    Swal.fire({
      icon: 'question',
      title: 'Confermi di voler effettuare questa operazione?',
      input: 'checkbox',
      inputPlaceholder: 'Conferma',
      text: "L'operazione non potrà essere annullata in maniera automatica",
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      inputValidator: value => !value && "Devi spuntare la casella di conferma per eliminare l'elemento"
    }).then(function(result) {
      if (result.isConfirmed) {
        funcName[`onSuccess`](params);
      }
    })
  }


  confermaAnnulla(funcName, params = null) {
    const that = this;
    Swal.fire({
      icon: 'question',
      title: 'Confermi di voler annullare?',
      text: "Eventuali dati non salvati andranno persi",
      showCancelButton: true,
      confirmButtonText: "SI",
      cancelButtonText: 'NO',
    }).then(function(result) {
      if (result.isConfirmed) {
        funcName[`onSuccess`](params);
      }
    })
  }


  cantDelete() {
    Swal.fire({
      icon: 'error',
      title: 'Impossibile eliminare',
      text: 'Questo elemento è collegato ad altri elementi e non può essere eliminato'
    })
  }
}
