import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { GenericItem } from 'src/app/components/class/generic-item';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Anagrafica } from '../anagrafica';
import { AnagraficaService } from '../anagrafica.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-anagrafica-form',
  templateUrl: './anagrafica-form.component.html',
  styleUrls: ['./anagrafica-form.component.scss']
})
export class AnagraficaFormComponent implements OnInit {
  @Input() cf: string = "";
  @Input() natoIl: string = "";
  @Input() myItem: Anagrafica;
  @Input() fromOrdine: number = -1;
  @Output() formReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSubmitDoneEmitter = new EventEmitter();
  @Output() onBackEmitter = new EventEmitter();

  @ViewChild('navBar') nav;

  arrayCampi1: any[any];
  arrayCampi2: any[any];
  arrayCampi3: any[any];
  arrayStati: GenericItem[];
  arrayProvince: GenericItem[];
  myForm1: FormGroup;
  myForm2: FormGroup;
  myForm3: FormGroup;
  ready: boolean = false;
  testoPrivacy: string;
  arrayPulsanti: any[any];
  activeTab: number = 1;
  loading: boolean = false;

  constructor(
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    private service: AnagraficaService
  ) {
    this.arrayCampi1 = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'cognome' : {'id' : 'cognome', 'nome': 'Cognome', 'required': true},
      'nome' : {'id' : 'nome', 'nome': 'Nome', 'required': true},
      'natoIl' : {'id' : 'natoIl', 'nome': 'Nato il', 'type': 'DTA', 'required': true, 'readonly': true},
      'natoA' : {'id' : 'natoA', 'nome': 'Nato a', 'required': true},
      'natoAProvincia' : {'id' : 'natoAProvincia', 'nome': 'Provincia di nascita', 'type': 'SEL', 'required': true},
      'nazionalita' : {'id' : 'nazionalita', 'nome': 'Nazionalità', 'type': 'SEL', 'required': true},
      'cf' : {'id' : 'cf', 'nome': 'Codice fiscale', 'type': 'CF', 'required': true, 'readonly': true},
      'telefono' : {'id' : 'telefono', 'nome': 'Telefono', 'required': true},
      'telefonoFisso' : {'id' : 'telefonoFisso', 'nome': 'Altro telefono'},
      'email' : {'id' : 'email', 'nome': 'Email', 'required': true, 'type': 'EML'},
      'pec' : {'id' : 'pec', 'nome': 'Pec', 'type': 'EML'},
      'note' : {'id' : 'note', 'nome': 'Note e/o comunicazioni varie', 'tpye': 'TXA'},
    }
    this.arrayCampi2 = {
      'indirizzo' : {'id' : 'indirizzo', 'nome': 'Indirizzo', 'required': true},
      'citta' : {'id' : 'citta', 'nome': 'Città', 'required': true},
      'provincia' : {'id' : 'provincia', 'nome': 'Provincia', 'type': 'SEL', 'required': true},
      'cap' : {'id' : 'cap', 'nome': 'Cap', 'shortInput': true, 'type': 'TXT', 'required': true},
      'stato' : {'id' : 'stato', 'nome': 'Stato', 'type': 'SEL', 'required': true},
    }
    this.arrayCampi3 = {
      'consensoPrivacy' : {'id' : 'consensoPrivacy', 'nome': 'Ho letto ed accetto l\'informativa', 'type': 'BOL', 'required': true},
    }

    this.arrayPulsanti = {
      'avanti' : {'type' : 'forward', 'ML': true},
      'indietro' : {'type' : 'back'},
      'conferma' : {'type' : 'conferma', 'ML': true},
    };

  }

  ngOnInit(): void {
    let that = this;

    if ((this.myItem != undefined) && (this.myItem.id != undefined)) {
      this.arrayCampi1.cognome.readonly = true;
      this.arrayCampi1.nome.readonly = true;
      this.arrayCampi1.natoIl.readonly = true;
      this.arrayCampi1.natoA.readonly = true;
      this.arrayCampi1.natoAProvincia.readonly = true;
      this.arrayCampi1.nazionalita.readonly = true;
    }

    forkJoin([
      this.mainEngineService.getElencoStati(),
      this.mainEngineService.getElencoProvince(),
      this.service.getTestoPrivacy(),
    ])
    .subscribe(
      ([elencoStati, elencoProvince, testoPrivacy]) => {

      this.arrayStati = elencoStati;
      this.arrayProvince = elencoProvince;
      this.testoPrivacy = testoPrivacy;
      this.myForm1 = this.formComponentsService.createForm(this.arrayCampi1);
      this.myForm2 = this.formComponentsService.createForm(this.arrayCampi2);
      this.myForm3 = this.formComponentsService.createForm(this.arrayCampi3);

      if (this.cf != '') {
        this.myForm1.patchValue({ 'cf': this.cf });
      }
      if (this.natoIl != '') {
        this.myForm1.patchValue({ 'natoIl': this.natoIl });
      }

      if ((this.myItem != undefined) && (this.myItem.id != undefined)) {
        setTimeout(function(){
          that.myForm1.patchValue(that.myItem);
          that.myForm2.patchValue(that.myItem);
          that.myForm3.patchValue(that.myItem);
          that.myForm1.markAsPristine();
          that.myForm2.markAsPristine();
          that.myForm3.markAsPristine();
          that.formReady.emit(true);
        }, 200);
      }
      else {
        that.formReady.emit(true);
      }
      this.ready = true;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

  }

  stepBack(step: number) {
    this.nav.select(step);
  }


  checkTabForm(tabNum) {
    if (!this['myForm'+tabNum].valid) {
      this.formComponentsService.validateAllFormFields(this['myForm'+tabNum]);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return 0;
    }
    return 1;
  }

  stepForward(step: number) {
    let actStep = step - 1;
    if (!this.checkTabForm(actStep)) {
      return;
    }
    this.nav.select(step);
  }

  navChange(event) {
    if (!this.checkTabForm(this.activeTab)) {
      event.preventDefault();
    }
  }

  onSubmit() {
    for (let i = 1; i <= 3; i++) {
      if (!this.checkTabForm(i)) {
        this.nav.select(i);
        this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
        return;
      }
    }

    this.loading = true;

    this.service.sendItem({...this.myForm1.getRawValue(), ...this.myForm2.getRawValue(), ...this.myForm3.getRawValue()})
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.onSubmitDoneEmitter.emit();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onBack() {
    this.onBackEmitter.emit();
  }
}
