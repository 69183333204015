import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorsiElencoComponent } from './corsi-elenco/corsi-elenco.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { RouterModule } from '@angular/router';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { DomandeSchedaComponent } from './domande-scheda/domande-scheda.component';
import { DichiarazioniComponent } from './domande-scheda/dichiarazioni/dichiarazioni.component';
import { DomandeStatoComponent } from './domande-stato/domande-stato.component';
import { DomandeInvioComponent } from './domande-invio/domande-invio.component';



@NgModule({
  declarations: [
    CorsiElencoComponent,
    DomandeSchedaComponent,
    DichiarazioniComponent,
    DomandeStatoComponent,
    DomandeInvioComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    NgbNavModule,
    ReactiveFormsModule

  ]
})
export class CorsiModule { }
