import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GenericItem } from 'src/app/components/class/generic-item';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { DomandeService } from '../../corsi/domande.service';
import { ELEMENTI_DICHIARAZIONE } from '../../corsi/elementi-dichiarazione';
import { ImpostazioniDomanda } from '../../corsi/impostazioni-domanda';
import { Domanda } from '../domanda';

@Component({
  selector: 'app-domande-scheda',
  templateUrl: './domande-scheda.component.html',
  styleUrls: ['./domande-scheda.component.scss']
})
export class DomandeSchedaComponent implements OnInit {
  @ViewChild('navBar') nav;

  loading = false;
  titoloScheda: string = "Caricamento...";
  impostazioniDomanda: ImpostazioniDomanda;
  activeTab: number = 1;
  arrayCampi1: any[any];
  myForm1: FormGroup;
  arrayCampi2: any[any];
  myForm2: FormGroup;
  arrayCampi3: any[any];
  myForm3: FormGroup;
  arrayCampiBaseEsameTabella: any[any];
  arrayCampi3Tabella: any[any];
  myForm3Tabella: FormGroup;
  arrayCampi4: any[any];
  myForm4: FormGroup;
  arrayProvince: GenericItem[];
  arrayPulsanti: any[any];
  linkBase: string;
  tipologiaRichiesta: string = "";
  elencoTipologieRichiesta: GenericItem[];
  elencoCorsi: GenericItem[];
  testiIscrizione: string[];
  variabili: any[any];
  titoloTabDichiarazioni: string;
  elementiDichiarazioni: any[];
  dichiarazioniIscrizione: number[];
  ready: boolean = false;
  tipologia: string;
  id: number;
  myItem: Domanda;

  constructor(
    private mainEngineService: MainEngineService,
    private service: DomandeService,
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    private componentsService: ComponentsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.arrayCampi1 = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'}, // id genitore
      'cognome' : {'id' : 'cognome', 'nome': 'Cognome', 'required': true},
      'nome' : {'id' : 'nome', 'nome': 'Nome', 'required': true},
      'natoIl' : {'id' : 'natoIl', 'nome': 'Nato il', 'type': 'DTA', 'required': true},
      'natoA' : {'id' : 'natoA', 'nome': 'Nato a', 'required': true},
      'natoAProvincia' : {'id' : 'natoAProvincia', 'nome': 'Provincia di nascita', 'type': 'SEL', 'required': true},
      'cf' : {'id' : 'cf', 'nome': 'Codice fiscale', 'type': 'CF', 'required': true},
      'indirizzo' : {'id' : 'indirizzo', 'nome': 'Indirizzo', 'required': true},
      'citta' : {'id' : 'citta', 'nome': 'Città', 'required': true},
      'provincia' : {'id' : 'provincia', 'nome': 'Provincia', 'type': 'SEL', 'required': true},
      'cap' : {'id' : 'cap', 'nome': 'Cap', 'shortInput': true, 'type': 'TXT', 'required': true},
      'telefono' : {'id' : 'telefono', 'nome': 'Telefono', 'required': true},
      'email' : {'id' : 'email', 'nome': 'Email', 'required': true, 'type': 'EML'},
      'note' : {'id' : 'note', 'nome': 'Note e/o comunicazioni varie', 'tpye': 'TXA'},
    }

    this.arrayCampi2 = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'}, // id richiesta
      'idDomandaIscrizione' : {'id' : 'idDomandaIscrizione', 'nome': 'Tipologia richiesta', 'type': 'SEL', 'required': true},
      'idCorso' : {'id' : 'idCorso', 'nome': 'Scuola', 'type': 'SEL', 'required': true},
    }

    this.arrayCampi4 = {
      'autorizzazioneTrattamentoDati' : {'id' : 'autorizzazioneTrattamentoDati', 'nome': '<b>AUTORIZZA</b> ', 'type': 'BOL', 'fullW': true, 'required': true},
      'authPubblImg' : {'id' : 'authPubblImg', 'nome': '<b>AUTORIZZA</b> ', 'type': 'BOL', 'fullW': true},
    }

    this.arrayCampiBaseEsameTabella = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'esame' : {'id' : 'esame', 'nome': '', 'required': true},
      'aa' : {'id' : 'aa', 'nome': '', 'required': true},
      'sessione' : {'id' : 'sessione', 'nome': '', 'required': true},
      'votazione' : {'id' : 'votazione', 'nome': '', 'required': true},
      'issm' : {'id' : 'issm', 'nome': '', 'required': true},
    }

    this.arrayCampi3Tabella = new Array();

    this.arrayPulsanti = {
      'avanti' : {'type' : 'forward', 'ML': true},
      'indietro' : {'type' : 'back'},
      'conferma' : {'type' : 'conferma', 'ML': true},
      'aggiungiEsame': {'type' : 'custom', 'testo' : 'Aggiungi esame', 'colorClass' : 'info', 'icon': '', 'actionTooltip' : 'Aggiungi nuova riga'},
      'rimuoviEsame': {'type' : 'custom', 'testo' : 'Rimuovi', 'colorClass' : 'danger', 'icon': '', 'actionTooltip' : 'Rimuovi riga'},
    };

    this.elementiDichiarazioni = ELEMENTI_DICHIARAZIONE;
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        if (params.idAmmissione != undefined) { // domanda di iscrizione
        }
        else {
          this.tipologia = "ammissione";
          this.id = params.id;
        }

        if (this.tipologia == "iscrizione") {
          this.titoloScheda = "Domanda di iscrizione";
          this.titoloTabDichiarazioni = "Dichiarazioni";
        }
        else { // domanda di ammissione
          this.titoloTabDichiarazioni = "Allegato 1";
          forkJoin([
            this.service.getDomanda(this.tipologia, this.id),
            this.service.getImpostazioniDomanda(),
            this.mainEngineService.getElencoProvince(),
            this.service.getTipologieRichiesta('a', this.id),
          ])
          .subscribe(
            ([myItem,
              impostazioniDomanda,
              elencoProvince,
              elencoTipologieRichiesta
            ]) => {

              if (!this.id) {
                this.linkBase = "/corsi";
              }
              else{
                this.linkBase = "/corsi/dettaglio/"+this.id;
              }

              if (!myItem.richiestaModificabile) {
                this.notificationsService.show('error', '', 'Azione non permessa');
                this.onBack();
              }

              this.myItem = myItem;
              this.impostazioniDomanda = impostazioniDomanda;
              this.titoloScheda = "Domanda di ammissione anno accademico " + this.impostazioniDomanda.annoAccademico;
              if (!this.impostazioniDomanda.isMaggiorenne) {
                this.arrayProvince = elencoProvince;
                this.myForm1 = this.formComponentsService.createForm(this.arrayCampi1);
                if (this.id) {
                  this.myForm1.patchValue(this.myItem.genitore);
                  this.myForm1.markAsPristine();
                }
              }
              else {
                this.activeTab = 2;
              }

              this.elencoTipologieRichiesta = elencoTipologieRichiesta;
              this.myForm2 = this.formComponentsService.createForm(this.arrayCampi2);

              if (+this.id > 0) {
                this.myForm2.patchValue({'id' : this.id});
                this.myForm2.patchValue({'idDomandaIscrizione' : this.myItem.richiesta.idDomandaIscrizione});
              }

              this.ready = true;
              this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.mainEngineService.errorHandler(error);
          });
        }
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  checkTabForm(tabNum) {
    let pass = true;
    if (!this['myForm'+tabNum]) { // per escludere il controllo su tab1 nel caso in cui lo studente è maggiorenne (e quindi non c'è tab genitore)
      return pass;
    }
    if (!this['myForm'+tabNum].valid) {
      this.formComponentsService.validateAllFormFields(this['myForm'+tabNum]);
      pass = false;
    }
    if (tabNum == 3) {
      this.t.controls.forEach(element => {
        if (!element.valid) {
          this.formComponentsService.validateAllFormFields(element);
          pass = false;
          return;
        }
      })
    }

    if (!pass) {
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
    }

    return pass;
  }

  stepForward(step: number) {
    let actStep = step - 1;
    if (!this.checkTabForm(actStep)) {
      return;
    }

    this.nav.select(step);
  }

  stepBack(step: number) {
    this.nav.select(step);
  }

  navChange(event) {
    if ((event.nextId > event.activeId) && !this.checkTabForm(this.activeTab)) {
      event.preventDefault();
    }
  }

  onSelectDomandaIscrizione(idDomanda) {
    this.tipologiaRichiesta = idDomanda;

    if (idDomanda == '') {
      return;
    }

    forkJoin([
      this.service.getDatiDomanda(idDomanda),
    ])
    .subscribe(
      ([datiDomanda]) => {
        this.elencoCorsi = datiDomanda.corsi;
        this.testiIscrizione = datiDomanda.testi;
        this.dichiarazioniIscrizione = datiDomanda.dichiarazioniIscrizione;
        this.variabili = datiDomanda.variabili;

        this.arrayCampi3 = {}
        this.dichiarazioniIscrizione.forEach((numElement) => {
          if (this.elementiDichiarazioni[numElement] == undefined) {
            return;
          }
          this.elementiDichiarazioni[numElement].nome = this.patchText(this.elementiDichiarazioni[numElement].nome);
          this.arrayCampi3['dich_'+numElement] = this.elementiDichiarazioni[numElement];
          if (this.elementiDichiarazioni[numElement].subItems) {
            this.elementiDichiarazioni[numElement].subItems.forEach(subElement => {
              if (subElement.items != undefined) {
                subElement.inline = true;
              }
              this.arrayCampi3[subElement.id] = subElement;
            });
          }
        });
        this.arrayCampi3['dich_99'] = this.elementiDichiarazioni[99];
        this.myForm3 = this.formComponentsService.createForm(this.arrayCampi3);

        this.myForm3Tabella = this.formComponentsService.createForm({});
        this.myForm3Tabella.addControl("esamiSostenuti", new FormArray([]));

        this.dichiarazioniIscrizione.forEach((numElement) => {
          if (this.elementiDichiarazioni[numElement] == undefined) {
            return;
          }
          if (this.arrayCampi3['dich_'+numElement].altRequired) {
            this.myForm3.get(this.arrayCampi3['dich_'+numElement].id).setValidators([Validators.required]);
            this.myForm3.get(this.arrayCampi3['dich_'+numElement].id).disable();
            this.myForm3.controls[this.arrayCampi3['dich_'+numElement].id].setValue(true);
          }
          if (this.elementiDichiarazioni[numElement].subItems) {
            this.arrayCampi3['dich_'+numElement].subItems.forEach(subElement => {
              if (subElement.id == undefined) {
                return;
              }
              this.myForm3.get(subElement.id).disable();
            });
          }
        });

        this.arrayCampi4['autorizzazioneTrattamentoDati'].nome += this.testiIscrizione['trattamentoDati'];
        this.arrayCampi4['authPubblImg'].nome += this.testiIscrizione['pubblicazioneImmagini'];
        this.myForm4 = this.formComponentsService.createForm(this.arrayCampi4);

        if (this.id) {
          let that = this;
          setTimeout(function(){
            that.myForm2.patchValue({'idCorso' : that.myItem.richiesta.idCorso.toString()});
            that.myForm2.markAsPristine();
            that.myForm3.patchValue(that.myItem.dichiarazioni);
            that.myForm3.markAsPristine();
            that.myForm4.patchValue(that.myItem.autorizzazioni);
            that.myForm4.markAsPristine();

            if (that.myItem.esamiDichiarati.length > 0) {
              that.myItem.esamiDichiarati.forEach((field, index) => {
                if (index > 0) {
                  that.aggiungiRigaEsamiSostenuti();
                }
                that.t.controls[index].patchValue(field);
              });
            }

          }, 200);
        }
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
    });
  }


  patchText(text: string) {
    this.variabili.forEach(element => {
      text = text.replace("["+element.var+"]", element.value);
    });
    return text;
  }

  onClickCheckbox(event) {
    if (this.arrayCampi3[event.id].subItems == undefined) {
      return;
    }

    this.arrayCampi3[event.id].subItems.forEach(element => {
      if (event.checked) {
        this.myForm3.get(element.id).enable();
        this.myForm3.get(element.id).setValidators([Validators.required]);
      }
      else {
        this.myForm3.get(element.id).disable();
        this.myForm3.get(element.id).clearValidators();
      }
      this.myForm3.get(element.id).updateValueAndValidity();
    });
  }

  onClickEsamiSostenuti(event) {
    if (event.checked) {
      this.aggiungiRigaEsamiSostenuti();
    }
    else {
      this.t.clear();
    }
  }

  get f() { return this.myForm3Tabella.controls; }
  get t() { return this.f.esamiSostenuti as FormArray; }

  aggiungiRigaEsamiSostenuti() {
    this.t.push(this.formBuilder.group({
      esame: ['', Validators.required],
      aa: ['', Validators.required],
      sessione: ['', Validators.required],
      votazione: ['', Validators.required],
      issm: ['', Validators.required],
    }));

    this.arrayCampi3Tabella.push(this.arrayCampiBaseEsameTabella);
  }

  rimuoviEsame(i: number) {
    this.t.removeAt(i);
  }

  onSubmit() {
    for (let i = 1; i <= 4; i++) {
      if (!this.checkTabForm(i)) {
        this.nav.select(i);
        return;
      }
    }

    this.loading = true;

    let form1Value = this.myForm1 ? this.myForm1.getRawValue() : null;

    this.service.sendAmmissione(form1Value, this.myForm2.getRawValue(), this.myForm3.getRawValue(), this.myForm3Tabella.getRawValue(), this.myForm4.getRawValue())
    .pipe(first())
    .subscribe(
      (idRichiesta) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate(['/corsi/dettaglio/' + idRichiesta]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }
}
