import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';

@Component({
  selector: 'app-dichiarazioni',
  templateUrl: './dichiarazioni.component.html',
  styleUrls: ['./dichiarazioni.component.scss']
})
export class DichiarazioniComponent implements OnInit {
  @Output() public onClickCheckboxEmitter = new EventEmitter<any>();

  @Input() myForm: FormGroup;
  @Input() config: any;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  onClickCheckbox(checked) {
    this.onClickCheckboxEmitter.emit({id: this.config.id, checked: checked});
  }

}
