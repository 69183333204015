import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
    {
      label: 'Dati anagrafici',
      icon: 'fas fa-user-edit',
      link: '/anagrafica',
    },
    {
      label: 'Corsi',
      icon: 'fas fa-chalkboard-teacher',
      link: '/corsi',
    },
];
