import { Component, OnInit } from '@angular/core';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Domanda } from '../domanda';
import { DomandeService } from '../domande.service';

@Component({
  selector: 'app-corsi-elenco',
  templateUrl: './corsi-elenco.component.html',
  styleUrls: ['./corsi-elenco.component.scss']
})
export class CorsiElencoComponent implements OnInit {
  data: Domanda[];
  ready: boolean = false;

  constructor(
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private service: DomandeService,
  ) { }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);

    this.service.getElencoAmmissioni()
    .subscribe(
      data => {
        this.data = data;
        this.ready = true;
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

}
