import { Component, OnInit } from '@angular/core';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Anagrafica } from '../anagrafica';
import { AnagraficaService } from '../anagrafica.service';

@Component({
  selector: 'app-anagrafica-scheda',
  templateUrl: './anagrafica-scheda.component.html',
  styleUrls: ['./anagrafica-scheda.component.scss']
})
export class AnagraficaSchedaComponent implements OnInit {
  loading = false;
  myItem: Anagrafica;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;
  arrayCampi: any[any];

  constructor(
    private formComponentsService: FormInputService,
    private service: AnagraficaService,
    private mainEngineService: MainEngineService,
    private componentsService: ComponentsService
  ) {
    this.linkBase = "/";
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);

    this.service.getItem()
    .subscribe(data => {
      this.myItem = data;
      this.titoloScheda = "Modifica dati anagrafici";
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }




}
